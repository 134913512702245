<template>
    <div class="fatiaowind show" :style="lawDialogStyle" id="dragbox"
         style="box-shadow: 0 0 10px rgba(0,0,0,.3); border-radius: 3px;">
        <div class="wind-head" @mousedown="lawMousedown">
            <h6 class="title">法律法规汇编</h6>
            <div>
                <button v-if="!lawDiaglogIsExpand" class="button changewind" @click="handleExpandLawDialog"></button>
                <button v-if="lawDiaglogIsExpand" class="button changewind big" @click="handleExpandLawDialog"></button>
                <button class="button off" @click="closeLaw"><i class="el-icon-close"></i></button>
            </div>
        </div>
        <div class="wind-body">
            <!--法条目录区-->
            <div class="catalogue" id="catalogue" ref="catalogue" :style="lawDialogCatalogWidth">
                <div class="top"><h6 class="title">章节导航</h6>
                    <button class="button iconfont"><i class="el-icon-arrow-left"></i></button>
                </div>
                <div class="search">
                    <div class="input-parent">
                        <input type="text" class="input-param" v-model="lawSearchContent"
                               @keyup.enter="handleSearchLaw">
                        <button title="搜索" class="button searchbox_button" @click="handleSearchLaw"></button>
                    </div>
                    <div class="next-btn">
                        <div title="上一个" class="button iconfont icon-left2" @click="handleNextSearch(false)"><i
                            class="el-icon-arrow-left"
                            style="font-weight: bold; font-size: 16px;"></i>
                        </div>
                        <div title="下一个" class="button iconfont icon-right2" @click="handleNextSearch(true)"><i
                            class="el-icon-arrow-right"
                            style="font-weight: bold; font-size: 16px;"></i>
                        </div>
                    </div>
                </div>
                <div class="menu">
                    <div id="treeDemo" class="ztree"></div>
                </div>
            </div>
            <!-- 法条内容区-->
            <div class="contents">
                <div class="menu-border" @mousedown="lawDragWidth"></div>
                <div v-loading="isLoading" class="scroll-box">
                    <div class="nolist" v-if="!tempLawList.data">法律法规汇编</div>
                    <div v-else class="law-content" :id="tempLawList.data.id" style="padding-bottom: 20px;">
                        <!-- 先放法律主体 -->
                        <br>{{ tempLawList.data.name }}<br>{{ tempLawList.data.content }}
                        <!-- 放编 -->
                        <div v-for="(item1, key1) in tempLawList.children" :key="key1" class="law-content"
                             :id="item1.data.id">
                            <br>{{ clearText(item1.data.name) }}
                            {{ item1.data.content }}
                            <!-- 放章节 -->
                            <div v-for="(item2, key2) in item1.children" :key="key2" class="law-content"
                                 :id="item2.data.id">
                                <br>{{ clearText(item2.data.name) }}
                                {{ item2.data.content }}
                                <!-- 放小节 -->
                                <div v-for="(item3, key3) in item2.children" :key="key3" class="law-content"
                                     :id="item3.data.id">
                                    <br>{{ clearText(item3.data.name) }}
                                    {{ item3.data.content }}
                                    <!-- 放法条 -->
                                    <div v-for="(item4, key4) in item3.children" :key="key4" class="law-content"
                                         :id="item4.data.id">
                                        <br>{{ clearText(item4.data.name) }}
                                        {{ item4.data.content }}
                                        <div v-for="(item5, key5) in item4.children" :key="key5" class="law-content"
                                             :id="item5.data.id">
                                            {{ clearText(item5.data.name) }}
                                            {{ item5.data.content }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@ztree/ztree_v3/js/jquery.ztree.core.min"
import "@ztree/ztree_v3/js/jquery.ztree.excheck.min"
import $ from "jquery";
import {construct} from "@aximario/json-tree";

export default {
    name: "subjectiveLaw",
    data() {
        return {
            lawSearchContent: '',
            lawSearchList: [],
            lawSearchTempIndex: 0,
            lawDialogStyle: {width: '52%', height: '68%', left: '28%', top: '15%'},
            lawDialogCatalogWidth: {width: '408px'},
            lawDiaglogIsExpand: false,
            // ztree 配置
            setting: {
                callback: {
                    onClick: this.zTreeOnClick
                },
                data: {
                    key: {name: 'name'}
                }
            },
            zNodes: [],
            tempLawList: {},
            tempLawId: '',
            isLoading: false
        }
    },
    created() {
        if (window.innerWidth < 1200) {
            this.lawDialogStyle.height = (window.innerHeight * 0.7) + 'px'
            this.lawDialogStyle.width = (window.innerWidth * 0.7) + 'px'
            this.lawDialogStyle.top = (window.innerHeight * 0.1) + 'px'
            this.lawDialogStyle.left = (window.innerWidth * 0.1) + 'px'
        }
    },
    mounted() {
        //  获取法条目录
        this.$api.get('/pc/lawTree/getLawDirTree').then(res => {
            this.zNodes = construct(res.data, {
                id: 'id',
                pid: 'parentId',
                children: 'children'
            })
            $.fn.zTree.init($("#treeDemo"), this.setting, this.zNodes);
        })
    },
    methods: {
        // 清除样式
        clearText(val) {
            val = val.replace(/[\s]$/g, '')
            return val
        },
        closeLaw() {
            this.$emit("close")
        },
        // 法条框左右调节
        lawDragWidth(e) {
            let orginWidth = this.$refs.catalogue.offsetWidth
            let startX = e.clientX
            let _this = this
            document.onmousemove = function (ev) {
                let endX = ev.clientX
                _this.lawDialogCatalogWidth.width = (orginWidth + (endX - startX)) + 'px'
            };
            document.onmouseup = function () {
                document.onmousemove = null;
                document.onmouseup = null;
            };
        },
        // 法条拖拽
        lawMousedown(event) {
            this.selectElement = document.getElementById("dragbox");
            const div1 = this.selectElement;
            this.selectElement.style.cursor = "move";
            const distanceX = event.clientX - this.selectElement.offsetLeft;
            const distanceY = event.clientY - this.selectElement.offsetTop;
            document.onmousemove = function (ev) {
                const oevent = ev || event;
                div1.style.left = oevent.clientX - distanceX + "px";
                if (oevent.clientY - distanceY >= 0 && oevent.clientY - distanceY < window.innerHeight - 100) {
                    div1.style.top = oevent.clientY - distanceY + "px";
                }
            };
            document.onmouseup = function () {
                document.onmousemove = null;
                document.onmouseup = null;
                div1.style.cursor = "default";
            };
        },
        // 点击法条目录
        zTreeOnClick(event, treeId, treeNode) {
            if (this.tempLawId !== treeNode.lawId && !(treeNode.type === 0 && this.tempLawId == treeNode.id)) {
                this.isLoading = true
                this.$api.get('/pc/lawTree/getLawListByLawDir', {
                    params: {
                        id: treeNode.id
                    }
                }).then(res => {
                    this.tempLawList = res.data
                    this.tempLawId = treeNode.type === 0 ? treeNode.id : treeNode.lawId
                    setTimeout(function () {
                        const anchorEle = document.getElementById(treeNode.id);
                        if (anchorEle) {
                            anchorEle.scrollIntoView(true);
                        }
                    }, 200);

                    this.isLoading = false
                }).catch(err => {
                    console.log(err)
                    this.isLoading = false
                })
            } else {
                const anchorEle = document.getElementById(treeNode.id);
                if (anchorEle) {
                    anchorEle.scrollIntoView(true);
                }
            }
        },
        handleNextSearch(isNext) {
            var treeObj = $.fn.zTree.getZTreeObj("treeDemo");
            if (isNext) {
                if (this.lawSearchTempIndex + 1 < this.lawSearchList.length) {
                    this.lawSearchTempIndex = this.lawSearchTempIndex + 1
                    treeObj.selectNode(this.lawSearchList[this.lawSearchTempIndex])
                    this.scrollToId(this.lawSearchList[this.lawSearchTempIndex].tId)
                }
            } else {
                if (this.lawSearchTempIndex - 1 >= 0) {
                    this.lawSearchTempIndex = this.lawSearchTempIndex - 1
                    treeObj.selectNode(this.lawSearchList[this.lawSearchTempIndex])
                    this.scrollToId(this.lawSearchList[this.lawSearchTempIndex].tId)
                }
            }
        },
        scrollToId(id) {
            const anchorEle = document.getElementById(id);
            if (anchorEle) {
                anchorEle.scrollIntoView(true);
            }
        },
        handleSearchLaw() {
            var treeObj = $.fn.zTree.getZTreeObj("treeDemo");
            this.lawSearchList = treeObj.getNodesByParamFuzzy("name", this.lawSearchContent, null);
            if (this.lawSearchList && this.lawSearchList.length > 0) {
                treeObj.selectNode(this.lawSearchList[0])
                this.lawSearchTempIndex = 0

                // 兼容部分浏览器不跳转的问题
                this.scrollToId(this.lawSearchList[0].tId)

            }
        },
        handleExpandLawDialog() {
            if (this.lawDiaglogIsExpand) {
                // 缩小操作
                this.lawDialogStyle.top = '119px'
                this.lawDialogStyle.left = '640px'
                this.lawDialogStyle.width = '50%'
                this.lawDialogStyle.height = '70%'
                this.lawDialogCatalogWidth.width = '448px'
                this.lawDiaglogIsExpand = false
            } else {
                // 全屏操作
                this.lawDialogStyle.top = '0'
                this.lawDialogStyle.left = '0'
                this.lawDialogStyle.width = '100%'
                this.lawDialogStyle.height = '100%'
                this.lawDialogCatalogWidth.width = '869px'
                this.lawDiaglogIsExpand = true
            }
        },
    }
}
</script>

<style scoped>
@import "~@ztree/ztree_v3/css/zTreeStyle/zTreeStyle.css";

body {
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    min-width: 960px;
    background: #2d6dae;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.law-content {
    font-family: SimSun !important;
    margin-bottom: 1em !important;
    color: #404040 !important;
    font-size: 16px !important;
    line-height: 1.4;
    white-space: pre-wrap;
}

.nolist {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #444;
    margin: auto;
    height: 1em;
    font-weight: 600;
    line-height: 1;
    font-size: 28px;
    font-family: SimSun;
}

.wind-body .contents .scroll-box {
    white-space: pre-wrap;
    overflow: auto;
    line-height: 1.3;
    height: 100%;
    padding: 20px;
}

.menu-border {
    position: absolute;
    height: 100%;
    width: 5px;
    top: 0;
    left: 0;
    cursor: e-resize;
}

.wind-body .contents {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
}

.ztree {
    margin: 0;
    padding: 5px;
    color: #333;
}

.menu {
    width: 100%;
    height: calc(100% - 62px);
    overflow: auto;
}

.search .next-btn {
    width: 65px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 10px;
    cursor: pointer;
}

.search .next-btn .icon-left2 {
    margin-right: 10px;
}

.search .next-btn .button {
    float: left;
    width: 18px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dfdfdf;
    color: #999;
    background: #fafafa;
    cursor: pointer;
}

.search .input-parent .searchbox_button {
    position: absolute;
    right: 4px;
    top: 5px;
    cursor: pointer;
    opacity: .6;
    z-index: 1;
    width: 16px;
    height: 16px;
    background: url(../../../assets/img/searchbox_button.png) no-repeat;
}

.search .input-param {
    font-size: 14px;
    border: 0;
    padding: 0 0 0 4px;
    white-space: normal;
    vertical-align: top;
    outline-style: none;
    resize: none;
    height: 28px;
    line-height: 28px;
    border-bottom: 1px solid #666;
    width: 100%;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.search .input-parent {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 30px;
    width: 270px;
}

.catalogue .top .iconfont {
    padding: 0 10px;
    font-size: 18px;
}

.catalogue .top .title {
    padding-left: 10px;
    font-family: SimSun;
    font-size: 14px;
    color: #404040;
}

.catalogue .top {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #ddd;
    background-color: #fafafa;
}

.wind-body .catalogue {
    width: 448px;
    height: 100%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-right: 1px solid #ddd;
    overflow: hidden;
    -webkit-transition: width .2s;
    transition: width .2s;
}

.wind-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-row: 1;
    height: calc(100% - 40px);
}

.wind-head .off {
    font-size: 19px;
}

.wind-head .changewind:before {
    content: "";
    position: relative;
    z-index: 30;
    display: block;
    margin: auto;
    width: 10px;
    height: 9px;
    border: 1px solid #666;
    border-top-width: 2px;
    background: #fff;
}

.wind-head .changewind.big:after {
    display: block;
}

.wind-head .changewind:after {
    content: "";
    position: absolute;
    z-index: 28;
    display: none;
    top: 11px;
    right: 5px;
    margin: auto;
    width: 11px;
    height: 11px;
    border: 1px solid #333;
}


.wind-head .changewind {
    position: relative;
    z-index: 25;
}

.wind-head .button {
    float: left;
    width: 30px;
    height: 40px;
}

.wind-head .title {
    font-size: 14px;
    line-height: 40px;
    margin-left: 20px;
    font-family: SimSun;
    color: #404040;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-corner {
    background: #82AFFF;
}

::-webkit-scrollbar-thumb {
    background: rgba(50, 50, 50, 0.4);
    border-radius: 4px;
}

li {
    list-style: none;
}

.subjective-question .area-anwwer .small-title .zoom-window {
    width: 60px;
    height: 21px;
    margin-left: 2px;
    color: #000;
    font-size: 12px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -16px 0;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.subjective-question .area-anwwer .anwwer .hint {
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
}

.subjective-question .area-anwwer .anwwer .text {
    font-size: 17px;
    white-space: pre-wrap;
    line-height: 1.5;
    letter-spacing: 1px;
    margin-bottom: 5px;
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
}

.subjective-question .area-anwwer .anwwer {
    height: 100px;
    -webkit-box-flex: 1;
    flex: 1;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    overflow: auto;
}

.subjective-question .area-anwwer .small-title .btnss {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.subjective-question .area-anwwer .small-title .quest.active {
    background: #fb0;
}

.leftnav .subjcard .subitem .a.active {
    color: #a935da !important;
}

.subjective-question .area-anwwer .small-title .quest {
    float: left;
    width: 60px;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    background: #f5f5f5;
    cursor: pointer;
}

.subjective-question .area-anwwer .small-title .quest-group {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-top: 5px;
    padding-right: 10px;
    overflow: hidden;
}

.subjective-question .area-anwwer .small-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-bottom: 5px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-bottom: 1px solid #a1b5fa;
}

.subjective-question .area-anwwer .tuodong {
    height: 6px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background: #a1b5fa;
    cursor: n-resize;
}

.subjective-question .area-anwwer {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100px;
    min-height: 100px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.subjective-question .title-subj .text .unedit {
    white-space: pre-wrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
}

.subjective-question .title-subj .text {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100px;
    overflow: auto;
    background: #fff;
    padding: 10px;
    line-height: 1.2;
    letter-spacing: -.2px;
    color: #000;
}

.subjective-question .title-subj .head-title .buttons .zoom-window {
    width: 58px;
    height: 21px;
    margin-left: 5px;
    color: #000;
    font-size: 12px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -16px 0;
}

.subjective-question .title-subj .head-title .buttons {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.subjective-question .title-subj .head-title .title {
    float: left;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.subjective-question .title-subj .head-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 40px;
    line-height: 40px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-bottom: 5px;
    border-bottom: 1px solid #a1b5fa;
}

.subjective-question .title-subj {
    height: 40%;
    min-height: 200px;
    max-height: 500px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.subjective-question .title-subj, .subjective-question {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.paperTitle .theme {
    font-size: 12px;
    line-height: 12px;
    margin-left: 6px;
    cursor: pointer;
    height: 12px;
    padding: 4px;
    margin-top: 4px;
    float: left;
    -webkit-box-sizing: initial;
    box-sizing: initial;
}

button {
    outline: none;
    -webkit-appearance: none;
    cursor: pointer;
}

.paperTitle .themeLi .setfontsize, .paperTitle .themeLi .setStyle {
    float: left;
}

.paperTitle .li .mark, .paperTitle .li .themeP, .paperTitle .li .unmark {
    width: 58px;
    height: 21px;
    float: left;
    margin: 4px 2px auto 0;
    color: #000;
    font-size: 12px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -16px 0;
}

.paperTitle .single {
    float: left;
    height: 29px;
    margin-right: 6px;
    width: 109px;
    font-size: 14px;
    font-weight: bolder;
    line-height: 29px;
    text-align: center;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -212px -33px;
}

.paperTitle .li {
    float: left;
    display: block;
    cursor: pointer;
}

.paperTitle {
    position: absolute;
    left: 22px;
    top: 10px;
    right: 0;
    z-index: 40;
    height: 29px;
    background: url(../../../assets/img/titlebg.jpg) repeat-x;
}

.center .content .pack-up .pack-up-btn {
    margin-top: 200px;
    width: 10px;
    height: 62px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -16px -38px;
    cursor: pointer;
}

.center .content .pack-up {
    position: absolute;
    left: 0;
    top: 0;
    width: 11px;
    height: 100%;
    border-right: 1px solid #acc4f0;
}

.center .content {
    -webkit-box-flex: 4;
    -ms-flex: 4;
    flex: 4;
    position: relative;
    padding-top: 40px;
    padding-left: 22px;
    padding-bottom: 50px;
    height: 100%;
}

.button {
    background: transparent;
    border: none;
    line-height: 1.1;
    outline: none !important;
    -webkit-appearance: none !important;
    user-select: none;
    font-size: 17px;
    padding: 0;
}

.options-bar .button {
    width: 85px;
    height: 31px;
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -121px -33px;
}

.options-bar .topicnub {
    margin: 0 10px;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

.options-bar .testflag {
    float: left;
    margin-top: 8px;
    margin-right: 5px;
}

.leftnav {
    width: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 100%;
    border-right: 1px solid #acc4f0;
    overflow: auto;
}

.leftnav.show .options-bar {
    padding-left: 200px;
}

.leftnav .fagui {
    width: 109px;
    height: 29px;
    text-align: center;
    line-height: 29px;
    font-size: 14px;
    margin: 15px auto;
    cursor: pointer;
    background: url(../../../assets/img/mkbgicon.png) -212px -33px;
}

.leftnav .submark {
    text-align: left;
    margin-left: 4px;
    padding-top: 5px;
    font-size: 18px;
    color: red;
    line-height: 24px;
}

.leftnav .subjcard .subitem .a:after {
    content: attr(data-type);
    display: inline;
    color: red;
}

.leftnav .subjcard .subitem {
    overflow: hidden;
}

.leftnav .subjcard .card-top.active:after {
    background-position: -530px -51px;
}

.leftnav .subjcard .subitem .a {
    display: block;
    min-width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #fff;
    float: left;
    color: #00f;
    cursor: pointer;
    font-family: Microsoft YaHei;
    text-decoration: underline;
}

.leftnav .subjcard .card-top:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 34px;
    height: 12px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -530px -35px;
}

.leftnav .subjcard .card-top {
    position: relative;
    padding-left: 10px;
    font-size: 14px;
    height: 28px;
    color: #000;
    text-align: left;
    font-weight: 600;
    line-height: 28px;
    background: url(../../../assets/img/left_menu_bg.jpg) repeat-x;
    cursor: pointer;
}

.leftnav .topic-card {
    display: none;
    width: 100%;
    height: 100%;
}

.leftnav.show .topic-card {
    display: block;
}

.mockinfo .submit .submit-btn {
    width: 80px;
    height: 27px;
    background: url(../../../assets/img/submit.jpg) repeat-x;
    border: 1px solid #c60;
    color: #000;
    font-size: 13px;
    cursor: pointer;
    border-radius: 0;
}

.mockinfo .submit .time {
    margin-right: 30px;
    color: #ff0;
}

.mockinfo .submit {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    right: 0;
    top: 0;
    height: 100%;
    width: 450px;
    padding-right: 20px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

b, strong, h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}

.mockinfo .paperinfo .title1 {
    margin-bottom: 1em;
}

.mockinfo .paperinfo {
    padding: 18px 0;
    text-align: center;
    line-height: 25px;
    color: #fff;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: 0;
}

.mockinfo .userinfo .other {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    font-weight: 600;
    padding: 7px 0;
    color: #fff;
}

.mockinfo .userinfo .userhead {
    display: block;
    float: left;
    width: 74px;
    height: 96px;
    max-height: 100%;
    margin-right: 10px;
    -o-object-fit: contain;
    object-fit: contain;
}

.mockinfo .userinfo {
    position: absolute;
    padding: 2px 10px;
    left: 0;
    top: 0;
    height: 100%;
    width: 400px;
}

.subjective-question .area-anwwer .small-title .txt {
    line-height: 28px;
    float: left;
    font-weight: 700;
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
}

.subjective-question .area-anwwer .anwwer .myanswer {
    display: block;
    -webkit-box-flex: 1;
    padding: 6px 10px;
    flex: 1;
    width: 100%;
    max-height: 78%;
    overflow: auto;
    border: none;
    resize: none;
    outline: none;
}

.subjective-question .title-subj .text .isedit {
    margin-bottom: 1em;
    white-space: pre-wrap;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
}

.mock-popuup .dialog_content .dialog_button .button {
    width: 84px;
    height: 31px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -33px -33px;
}

.mock-popuup .dialog_content .dialog_button {
    position: absolute;
    right: 20px;
    bottom: 25px;
    padding-left: 40px;
}

.mock-popuup .dialog_content .dialog_text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 210px;
    margin-top: 10px;
}

.mock-popuup .dialog_content {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 291px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.mock-popuup .dialog_main {
    position: absolute;
    z-index: 55;
    left: 50%;
    top: 42%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    min-height: 291px;
    width: 526px;
    text-align: left;
    background-color: #e4f2fb;
    background-image: url(../../../assets/img/ts_d2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 12px;
}


.center .content .pack-up .pack-up-btn.open {
    background-position: 0 -36px;
}

.wind-body .contents .scroll-box .text {
    font-family: SimSun;
    margin-bottom: 1em;
    color: #404040;
    font-size: 16px;
}

.fatiaowind {
    position: fixed;
    /*z-index: -100;*/
    left: 0;
    top: 0;
    margin: auto;
    width: 50%;
    height: 70%;
    min-height: 300px;
    min-width: 750px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0 0 0 0.3);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 3px;
    z-index: 9999 !important;
}

.fatiaowind.show {
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
    z-index: 200;
}

.wind-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    flex-shrink: 0;
    border-bottom: 1px solid #ddd;
    -webkit-box-pack: justify;
    justify-content: space-between;
    cursor: move;
}
</style>